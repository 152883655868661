<template>
  <div :class="isSP ? 'tire--sp' : 'tire--pc'">
    <!-- トップビジュアル -->
    <sp-visual v-if="isSP" class="tire__visual--sp" :img="require('@/assets/img/visual/tire_sp.jpeg')" title="タイヤ交換・販売" />
    <pc-visual v-else class="tire__visual--pc" :img="require('@/assets/img/visual/tire_pc.jpeg')" title="タイヤ交換・販売" />
    <!-- タイトル -->
    <h1 v-if="isSP" class="tire__title--sp">タイヤ交換・販売</h1>
    <h1 v-else class="tire__title--pc">タイヤ交換・販売</h1>
    <!-- 詳細情報 -->
    <sp-description v-if="isSP" class="tire__description--sp" :main="description.main" :sub="description.sub" />
    <pc-description v-else class="tire__description--pc" :main="description.main" :sub="description.sub" />
    <!-- 画像集 -->
    <v-img v-if="isSP" class="tire__images--sp" :src="require('@/assets/img/tire/image1_sp.jpeg')" :aspect-ratio="293/605"  cover />
    <v-img v-else class="tire__images--pc" :src="require('@/assets/img/tire/image1_pc.jpeg')" :aspect-ratio="681/1089"  cover />
    <!-- 問い合わせ -->
    <sp-contact v-if="isSP" :isRecruit="false" />
    <pc-contact v-else :isRecruit="false" />
    <!-- 最後の画像 -->
    <v-img v-if="isSP" class="tire__bottom--sp" :src="require('@/assets/img/tire/bottom1.jpeg')" :aspect-ratio="375/244"  cover />
    <v-img v-else class="tire__bottom--pc" :src="require('@/assets/img/tire/bottom1.jpeg')" :aspect-ratio="1280/650"  cover />
  </div>
</template>

<script>
import SpVisual from '@/components/common/visual/sp'
import PcVisual from '@/components/common/visual/pc'
import SpDescription from '@/components/common/description/sp'
import PcDescription from '@/components/common/description/pc'
import SpContact from '@/components/common/contact/sp'
import PcContact from '@/components/common/contact/pc'

export default {
  name: 'tire',
  components: { SpVisual, PcVisual, SpDescription, PcDescription, SpContact, PcContact },
  mounted () {
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    /**
     * @return {Object} 説明文
     */
    description () {
      return {
        main: '弊社のタイヤサービスカーで出張サービスを行っています。\n乗用車から大型トラックのタイヤ販売・取付け・パンク修理は弊社のタイヤアドバイザーにお任せ下さい。',
        sub: ''
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.tire {
  width: 100%;
  &--sp {
    @extend .tire;
    margin-top: $sp_header_height;
  }
  &--pc {
    @extend .tire;
    margin-top: $pc_header_height;
  }
  &__title {
    font-weight: 500;
    text-align: center;
    &--sp {
      @extend .tire__title;
      margin-top: 70px;
      font-size: 1.5rem;
    }
    &--pc {
      @extend .tire__title;
      margin-top: 171px;
      font-size: 3.5rem;
    }
  }
  &__description {
    &--sp {
      @extend .tire__description;
      margin-top: 55px;
    }
    &--pc {
      @extend .tire__description;
      margin-top: 100px;
    }
  }
  &__images {
    &--sp {
      @extend .tire__images;
      display: block;
      width: 293px;
      max-width: 80vw;
      height: 605px;
      max-height: 165.1877vw;
      margin: 60px auto 0 auto;
    }
    &--pc {
      @extend .tire__images;
      display: block;
      width: 681px;
      max-width: 60vw;
      height: 1089px;
      max-height: 95.94713vw;
      margin: 79px auto 0 auto;
    }
  }
  &__bottom {
    width: 100vw;
    &--sp {
      @extend .tire__bottom;
      height: 65.066vw;
    }
    &--pc {
      @extend .tire__bottom;
      height: 50.781vw;
    }
  }
}
</style>
